<template>
  <div class="extration-bdd-frais">
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click.prevent="openModal"
        >
          <font-awesome-icon icon="sync-alt" />
        </div>
      </template>
      <span>Actualiser écriture comptable</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      max-width="600px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-header"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            Actualiser l'écritures comptable des facture(s)
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text
          class="body-card center-text mt-2 custom-expansion-ecriture-compta"
        >
          <v-form>
            <!-- SOCIETE  -->
            <v-row class="row-top">
              <v-col cols xs="12" sm="12" md="12">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="filiale_id"
                  :items="computedGetVendeurs"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Société"
                  item-text="text"
                  multiple
                  chips
                  return-object
                  outlined
                  :deletable-chips="true"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  item-value="value"
                  :small-chips="true"
                  :clearable="true"
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                  @change="fetchFiltredFactureComptaAction"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- CLIENT  -->
            <v-row class="row-top">
              <v-col cols xs="12" sm="12" md="12">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="client_id"
                  :items="computedGetVendeurs"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Client"
                  item-text="text"
                  multiple
                  chips
                  return-object
                  outlined
                  :deletable-chips="true"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  item-value="value"
                  :small-chips="true"
                  :clearable="true"
                  :auto-focus="false"
                  no-data-text="Aucun client trouvé"
                  @change="fetchFiltredFactureComptaAction"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- TYPE FACTURE  -->
            <v-row class="row-top">
              <v-col cols xs="12" sm="12" md="12">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="type_facture"
                  :items="ListType"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Type"
                  item-text="text"
                  multiple
                  chips
                  return-object
                  outlined
                  :deletable-chips="true"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  item-value="value"
                  :small-chips="true"
                  :clearable="true"
                  :auto-focus="false"
                  no-data-text="Aucun Type trouvé"
                  @change="fetchFiltredFactureComptaAction"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="row-top">
              <!-- DATE DEBUT -->
              <v-col cols xs="6" sm="6" md="6" class="date-input-vuetify">
                <v-menu
                  v-model="isDatePickerDebutVisible"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_debut"
                      label="Date début"
                      outlined
                      readonly
                      hide-details
                      clearable
                      v-on="on"
                      v-bind="attrs"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner un date"
                      prepend-inner-icon="mdi-calendar"
                      @click:clear="
                        date_debut = null;
                        fetchFiltredFactureComptaAction();
                      "
                      color="#704ad1"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="date_debut"
                    no-title
                    locale="fr"
                    color="#704ad1"
                    header-color="#704ad1"
                    event-color="704ad1"
                    required
                    @change="fetchFiltredFactureComptaAction"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- DATE FIN -->
              <v-col class="date-input-vuetify " cols xs="6" sm="6" md="6">
                <v-menu
                  v-model="isDatePickerFinVisible"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_fin"
                      label="Date fin"
                      outlined
                      readonly
                      hide-details
                      v-on="on"
                      v-bind="attrs"
                      clearable
                      :persistent-placeholder="true"
                      placeholder="Sélectionner un date"
                      prepend-inner-icon="mdi-calendar"
                      @click:clear="
                        date_fin = null;
                        fetchFiltredFactureComptaAction();
                      "
                      color="#704ad1"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date_fin"
                    no-title
                    locale="fr"
                    color="#704ad1"
                    header-color="#704ad1"
                    event-color="#704ad1"
                    @change="fetchFiltredFactureComptaAction"
                  ></v-date-picker> </v-menu></v-col
            ></v-row>
            <!-- COMPTABILISE FACTURE  -->
            <template v-if="filiale_id.length > 0 && date_debut">
              <v-row>
                <v-label class="ml-4 mt-4 mb-3 title-tabel-global"
                  >Liste des factures
                  <span class="custom-badge-cdg" title="Nombre de facture">{{
                    getFiltredFactCompta.length
                  }}</span></v-label
                >
                <!-- <v-col cols="6">
                  <div class="btn-end-postion mt-2">
                    <v-text-field
                      label="Rechercher"
                      placeholder="Rechercher"
                      :persistent-placeholder="true"
                      outlined
                      v-model="search"
                      color="#704ad1"
                    ></v-text-field></div
                ></v-col> -->
                <!-- @input="testCheckFunction" -->
              </v-row>
              <v-progress-circular
                v-if="getLoderFactRefreshCompta"
                indeterminate
                class="ml-2"
                color="#704ad1"
              ></v-progress-circular>

              <v-row
                v-if="
                  getFiltredFactCompta.length == 0 && !getLoderFactRefreshCompta
                "
                class="ml-2"
                >Aucune facture trouvée</v-row
              >
              <v-row
                class=""
                v-if="
                  getFiltredFactCompta.length > 0 && !getLoderFactRefreshCompta
                "
              >
                <v-col cols="6" class="pt-0">
                  <v-checkbox
                    class="checked-tva margin-none"
                    color="#704ad1"
                    :value="true"
                    :label="'Tous'"
                    hide-details
                    v-model="check_all"
                    @change="handelChangeAll"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row
                class=""
                v-if="
                  getFiltredFactCompta.length > 0 && !getLoderFactRefreshCompta
                "
              >
                <v-col
                  cols="6"
                  class="pt-0"
                  v-for="(option, index) in computedGetFiltredFactCompta"
                  :key="index"
                >
                  <v-checkbox
                    @change="handelCheckOne"
                    class="checked-tva margin-none"
                    color="#704ad1"
                    :value="option.id"
                    :label="option.num"
                    hide-details
                    v-model="ids"
                  ></v-checkbox> </v-col></v-row
            ></template>
          </v-form>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="handleRefreshEcriture"
            :loading="loading"
            :class="{ loader: loading }"
            type="submit"
          >
            Actualiser
          </v-btn>
          <v-btn text @click="hideModal">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {},
  data() {
    return {
      loading: false,
      error: [],
      dialog: false,
      isDatePickerDebutVisible: false,
      isDatePickerFinVisible: false,
      filiale_id: [],
      client_id: [],
      type_facture: [],
      date_debut: null,
      date_fin: null,
      ids: [],
      loaderFacture: false,
      check_all: false,
      ListType: [
        { value: 'Facture', text: 'Facture' },
        { value: "Facture d'avoir", text: "Facture d'avoir" }
      ],
      search: null
    };
  },
  methods: {
    ...mapActions([
      'historiqueComptabilisationFacture',
      'exportFileInListExporte',
      'fetchFiltredFactureCompta',
      'resetFiltredFactureCompta',
      'refreshFiltredFactureCompta'
    ]),
    handelChangeAll() {
      if (this.check_all) {
        this.ids = this.computedGetFiltredFactCompta.map(i => i.id);
      } else {
        this.ids = [];
      }
    },
    handelCheckOne() {
      if (this.ids.length == this.computedGetFiltredFactCompta.length) {
        this.check_all = true;
      } else {
        this.check_all = false;
      }
    },
    async fetchFiltredFactureComptaAction() {
      if (this.filiale_id.length > 0 && this.date_debut != null) {
        let payload = {
          filiale_id: this.filiale_id.map(i => i.value),
          date_debut: this.date_debut
        };
        // if (this.date_debut) {
        //   payload.date_debut = this.date_debut;
        // }
        if (this.date_fin) {
          payload.date_fin = this.date_fin;
        }
        if (this.client_id.length) {
          payload.client_id = this.client_id.map(i => i.value);
        }
        if (this.type_facture.length) {
          payload.type_facture = this.type_facture.map(i => i.value);
        }
        payload.source = 'COTH';
        payload.loading = true;

        this.ids = [];
        this.loaderFacture = true;
        await this.fetchFiltredFactureCompta(payload);
        this.loaderFacture = false;
      } else {
        if (this.filiale_id.length > 0) {
          this.ids = [];
          this.resetFiltredFactureCompta();
        }
      }
    },
    async openModal() {
      this.dialog = true;
      this.loadingHistorique = true;
      await this.historiqueComptabilisationFacture();
      this.loadingHistorique = false;
    },
    async handleRefreshEcriture() {
      if (this.filiale_id.length == 0) {
        this.error = 'Sélectionnée au moins une société';
      } else {
        this.loading = true;
        let payload = { source: 'COTH', loading: true };
        if (this.ids.length) {
          payload.ids = this.ids;
        } else {
          if (this.filiale_id.length > 0) {
            payload.filiale_id = this.filiale_id.map(i => i.value);
          }
          if (this.client_id.length > 0) {
            payload.client_id = this.client_id.map(i => i.value);
          }
          if (this.type_facture.length > 0) {
            payload.type_facture = this.type_facture.map(i => i.value);
          }
          if (this.date_fin) {
            payload.date_fin = this.date_fin;
          }
          if (this.date_debut) {
            payload.date_debut = this.date_debut;
          }
        }
        const response = await this.refreshFiltredFactureCompta(payload);
        if (response.succes) {
          this.loading = false;
          this.dialog = false;
          this.$alert('', response.data, 'success');
          // this.$emit('reloadData');
          this.resetModal();
        } else {
          this.loading = false;
          this.error = 'Une erreur est survenue';
        }
      }
    },
    hideModal() {
      this.dialog = false;
      this.resetModal();
    },
    resetModal() {
      this.loading = false;
      this.error = [];
      this.filiale_id = [];
      this.client_id = [];
      this.type_facture = [];
      this.date_debut = null;
      this.date_fin = null;
      this.ids = [];
      this.check_all = false;
    },
    testCheckFunction() {
      if (
        this.search != null &&
        this.search != '' &&
        this.ids.length != this.computedGetFiltredFactCompta.length
      ) {
        this.check_all = false;
      }
    }
  },
  computed: {
    ...mapGetters([
      'getHistoriqueComptabilisationFacture',
      'getFiliaeOfResponsable',
      'getFiltredFactCompta',
      'getLoderFactRefreshCompta'
    ]),
    computedGetVendeurs() {
      let vendeurNames = this.getFiliaeOfResponsable.map(item => {
        return { value: item.id, text: item.name };
      });
      return vendeurNames;
    },
    computedGetFiltredFactCompta() {
      // if (this.search != null && this.search != '') {
      //   return this.getFiltredFactCompta.filter(item =>
      //     item.num.toLowerCase().includes(this.search.toLowerCase())
      //   );
      // }
      return this.getFiltredFactCompta;
    }
  },
  components: {},
  mounted() {}
};
</script>
<style lang="scss">
.custom-expansion-ecriture-compta {
  .v-select .v-label,
  .v-input--checkbox .v-label {
    font-size: 12px;
  }
}
</style>
